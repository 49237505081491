import "../Assets/js/swiper.js";
import "../Assets/js/search.js";
import "../Assets/js/navigation.js";
import "../Assets/js/accordion.js";
import "../Assets/js/text.js";

import { heroSwiper } from "../Assets/js/swiper.js";

document.addEventListener('Neos.NodeCreated', function(event) {
    const element = event.detail.element;

    if(element.querySelector('.hero--swiper')) {
        heroSwiper()
    }
})