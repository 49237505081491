// import JS Swiper + modules
import Swiper from "swiper";
import { Pagination, Autoplay, A11y } from "swiper";
import { initNeosEventItemCreated } from "./neosEvents";
import { initNeosEventItemSelected } from "./neosEvents";

export function heroSwiper() {
  document.querySelectorAll(".hero--swiper").forEach((slider) => {
      const isNeosBackend = document.body.classList.contains('neos-backend')

      const swiperItems = Array.from(slider.querySelectorAll('.swiper-slide'))
      if(swiperItems.length <= 1 && !isNeosBackend) {
          return
      }

      const swiper = new Swiper(slider, {
          modules: [Pagination, Autoplay, A11y],
          allowTouchMove: !isNeosBackend,
          slidesPerView: 1,
          spaceBetween: 0,
          watchOverflow: true,
          resizeObserver: true,
          observer: true,
          observeParents: true,
          observeSlideChildren: true,
          pagination: {
              el: ".swiper-pagination",
              clickable: true,
          },
      });

      if (!isNeosBackend) {
        swiper.params.autoplay = {
          delay: 9500,
          disableOnInteraction: false,
        };
        swiper.autoplay.start();
      }

      initNeosEventItemCreated(swiper, slider);
      initNeosEventItemSelected(swiper, slider);
  });
}

heroSwiper()