document.querySelectorAll('.accordion--item').forEach(item => {
    item.querySelector('button').addEventListener('click', () => {
        item.classList.toggle('active')

        //Close other Accordion-Items in same Accordion-Container
        item.closest('.accordion').querySelectorAll('.accordion--item').forEach(otherItem => {
            if (otherItem !== item) {
                otherItem.classList.remove('active')
            }
        })
    })
})
