if(document.querySelector('.headline--short')){
    document.querySelectorAll('.headline--short').forEach(text => {
        if(text.querySelector('span')){
            text.classList.add('headline--center')
        }
    })
}

document.addEventListener('Neos.NodeSelected', function (event) {
    const selectedNode = event.detail.node;

    if (selectedNode.nodeType === 'PX.Site:Content.Text') {
        const elementPath = selectedNode.properties._path;
        const textElement = document.querySelector('div[data-__neos-node-contextpath*="' + elementPath + '"]')

        const handleElementChange = () => {
            const isCentered = textElement.querySelector('span[style="text-align:center;"]')
            const headlineElement = textElement.querySelector('.headline')

            if (isCentered) {
                if(!headlineElement.classList.contains('headline--center')) {
                    headlineElement.classList.add('headline--center')
                }
                
            } else {
                if(headlineElement.classList.contains('headline--center')) {
                    headlineElement.classList.remove('headline--center')
                }
            }
        };

        // MutationObserver konfigurieren
        const observer = new MutationObserver(handleElementChange);

        // Beobachtungskonfiguration
        const config = { attributes: true, subtree: true };

        // Das Element beobachten
        observer.observe(textElement, config);
    }
});