const toggleMenu = document.querySelector('.toggle--burger')
const navigationDefault = document.querySelector('.navigation--header')
const navPointA = document.querySelectorAll('.navigation--header a.navigation--listitem--link')
const navPointSubA = document.querySelectorAll('.navigation--header a.subnavigation--listitem--link')
const navPointSpan = document.querySelectorAll('.navigation--header span.navigation--listitem--link')

if(window.location.pathname == '/' || window.location.pathname == '/en') {
    document.querySelector('header .logo').classList.add('current')
}

toggleMenu.addEventListener('click', () => {
    toggleMenu.classList.toggle('active')
    navigationDefault.classList.toggle('active')
})

navPointA.forEach(item => {
    item.addEventListener('click', () => {
        toggleMenu.classList.remove('active')
        navigationDefault.classList.remove('active')
    })
})
navPointSubA.forEach(item => {
    item.addEventListener('click', () => {
        toggleMenu.classList.remove('active')
        navigationDefault.classList.remove('active')
    })
})

navPointSpan.forEach(button => {
    button.addEventListener('click', () => {
        window.value = document.querySelector('body').getBoundingClientRect().top

        //!!! Deactivate All Navigationitems and SubNavigation
        //Navigation Item
        document.querySelectorAll('.navigation--header .navigation--listitem').forEach(item => {
            if (item.classList.contains('current')) {
                item.classList.remove('current')
            }
        })
        //Home-Logo
        if (document.querySelector('header .logo.current')) {
            document.querySelector('header .logo.current').classList.remove('current')
        }
        //Subnavigation
        document.querySelectorAll('.subnavigation').forEach(sub => {
            sub.classList.remove('active')
        })

        //!!! Activate Navigationitem and SubNavigation
        //Activate Item
        button.closest('.navigation--listitem').classList.add('current')
        //Activate Subnavigation
        const subnavigation = button.closest('.navigation--listitem.current').querySelector('.subnavigation')
        subnavigation.classList.add('active')
    })
})

//!!! Deactivate SubNavigation on Mobile
document.querySelectorAll('.subnavigation--back span').forEach(item => {
    item.addEventListener('click', () => {
        item.closest('.subnavigation').classList.remove('active')
        item.closest('.navigation--listitem').classList.remove('current')

        adjustCurrent()
    })
})

//!!! Close SubNavigation If User clicks outside the SubNavigation
window.addEventListener('click', event => {

    document.querySelectorAll('.navigation--header .subnavigation.active').forEach(item => {

        if (!document.querySelector('header').contains(event.target)) {
            item.classList.remove('active')
            item.closest('.navigation--listitem').classList.remove('current')

            adjustCurrent()
        }
    })
})

//!!! Close SubNavigation If User scrolls
window.addEventListener('scroll', () => {
    document.querySelectorAll('.subnavigation.active').forEach(item => {
        if(item) {
            if(window.innerWidth >= 900) {
                const documentCurrentPosition = document.querySelector('body').getBoundingClientRect().top
                const difference = window.value + documentCurrentPosition * -1

                if (difference >= 200 || difference <= -200) {
                    item.classList.remove('active')
                    item.closest('.navigation--listitem').classList.remove('current')

                    adjustCurrent()
                }
            }
        }
    })
})

function adjustCurrent() {
    document.querySelectorAll('.navigation--listitem--link').forEach(listitem => {
        const itemLink = listitem.getAttribute('href').split('/')[1]
        const currentLocation = window.location.pathname.split('/')[1]

        const itemLinkEN = listitem.getAttribute('href').split('/')[2]
        const currentLocationEN = window.location.pathname.split('/')[2]

        if (currentLocation == 'en') {
            if (itemLinkEN == currentLocationEN) {
                listitem.closest('.navigation--listitem').classList.add('current')
            }
        }
        else {
            if (itemLink == currentLocation) {
                listitem.closest('.navigation--listitem').classList.add('current')
            }
        }

        if(window.location.pathname == '/' || window.location.pathname == '/en') {
            document.querySelector('header .logo').classList.add('current')
        }
    })
}
